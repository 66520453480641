
<template>
  <div>
    <div v-if="!isLoading" class="row gy-2">
      <div v-if="request" class="col-12">
        <h5 class="mb-3">Request Details</h5>
        <div class="card overflow-hidden">
          <div class="card-body">
            <div class="row gx-2 gx-lg-4">
              <div class="col-10 col-sm-8">
                <div class="mb-3">
                  <div class="">
                    <h6 class="mb-0">Ref Number #: {{request.id}}</h6>
                  </div>
                </div>
              </div>
              <div class="col-2 col-sm-4 text-end">
                <div class="dropdown dropstart no-arrow">
                  <a class="dropdown-toggle btn btn-sm btn-light" href="#"
                    role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="ri-more-2-fill"></i>
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <button @click="popupModalShow = true" class="dropdown-item"
                        type="button" > Update request</button>
                    </li>
                    <li>
                      <button @click.prevent="deleteItem()"
                        class="dropdown-item" type="button"> Delete request</button>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-12">
                <div class="row g-3">
                  <div class="col-12">
                    <div class=""><strong>Status: </strong> <span v-html="statusBadge(request.status)"></span></div>
                  </div>
                  <div class="col-sm-6">
                    <div class=""><strong>Quantity: </strong> {{ $filters.number_format(request.quantity) }}</div>
                  </div>
                  <div class="col-sm-6">
                    <div class=""><strong>Tag: </strong> {{ request.tag }} </div>
                  </div>
                  <div class="col-12">
                    <div class=""><strong>Date sent: </strong> {{ $filters.date(request.created_at, 1) }}</div>
                  </div>
                  <div class="col-12">
                    <hr class="my-1">
                  </div>
                  <div class="col-12">             
                    <h6>Medicine</h6>
                    <div>
                      <p v-if="request.medicine" class="mb-0"> <strong>{{request.medicine.name}}</strong> <span v-if="request.medicine.type"></span>- {{request.medicine.type.title}}</p>
                      <small>{{request.medicine.description}}</small>
                    </div>
                  </div>
                  <div class="col-12">
                    <hr class="my-1">
                  </div>
                  <div class="col-12">             
                    <h6>Reason</h6>
                    <div v-html="preText(request.reason)">
                    </div>
                  </div>
                  <div v-if="request.pharmacist" class="mt-2">
                    <hr class="my-1">
                    <div>
                        <p class="small text-muted mb-1">
                            <span>Pharmacist</span>
                        </p>
                        <h3 class="card-title mb-1">
                        <span>
                            {{request.pharmacist.first_name}} {{request.pharmacist.last_name}}
                        </span>
                        </h3>
                        <p class="mb-0">{{request.pharmacist.mobile}} / {{request.pharmacist.email}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <is-loading v-else />
    <b-modal v-model="popupModalShow" hide-footer centered
      title="Update request" body-class="loading-viewport"
      title-class="fs-6" @hide="initializeAll()">
      <drug-request-form v-if="popupModalShow" @closeMe="initializeAll()"
      :editItem="request" :editMode="true" @updatedItem="updateItem($event)"/>
    </b-modal>
  </div>
  </template>
  
  <script>
  import IsLoading from '@/components/IsLoading.vue'
  import DrugRequestForm from '@/components/forms/DrugRequestForm.vue';
  
  export default {
    name: "drug-request-show",
    components:{
      IsLoading,
      DrugRequestForm
    },
    data() {
      return {
        isLoading: true,
        popupModalShow: false,
        request: null
      }
    },
    watch: {
      '$route.params.requestId': function (id){
        if(id) this.fetchItem()
      },
    },
    methods: {
      updateItem(item){
        Object.assign(this.request, item)
      },
      fetchItem(){
        this.isLoading = true
        this.$http.get(`/medicine-requests/${this.$route.params.requestId}/show`)
        .then((response) => {
          this.isLoading = false
          if(response.data.success){
            this.request = response.data.data
          }else{
            this.$router.push({path: '/error-404'})
          }
        })
      },
      deleteItem(){
        this.confirmDelete({
          text: "You want to delete a request permanently",
        }).then((result) => {
          if (result.value) {
            this.$store.dispatch("changeLoaderValue",true)
            this.$http.delete(`/medicine-requests/${this.request.id}/delete`)
            .then(response => {
              this.$store.dispatch("changeLoaderValue",false)
              if(response.data.success){
                this.$router.push({path: '/medicine-requests'})
              }
            })
          }
        });
      },
      initializeAll(){
        this.popupModalShow = false;
      }
    },
    created(){
      this.fetchItem()
    },
  }
  
  </script>
  
  